<template>
  <section>
    <profile-main />
  </section>
</template>

<script>
export default {
  name: 'Profile',
  components: {
    ProfileMain: () => import('@/components/Profile/ProfileMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
